/* eslint-disable @typescript-eslint/no-explicit-any */
import m, { BactaComponent, Store } from "bacta"
import { ButtonAttrs, Button } from "../sl/button.js"

export type QueryAttrs<T> = {
	query?: Store<T>
}

export type BaseButtonAttrs = {
	sl?: Partial<ButtonAttrs>
	slot?: "footer" | "label"
	postop?: Function
	id?: string
}

export type MainButtonAttrs<T> = BaseButtonAttrs & QueryAttrs<T>

// todo-james I think this component needs a rethink or I need to understand it better
// for now I've just used <any>
export const HarthButton: BactaComponent<MainButtonAttrs<any>> =
	() =>
	({ attrs: { sl = {}, ...attrs }, children = [] }) => {
		return m(
			Button,
			{
				...sl,
				onclick: async (e) => {
					const value = (e.currentTarget as HTMLInputElement)?.value

					attrs?.query?.update(() => value)

					if (attrs.postop) {
						await attrs.postop()
					}

					// if (attrs.type === "boolean") {
					// 	attrs.query.write(() => (e.target as HTMLInputElement)?.value ? true : false);
					// }
					// else if (attrs.type === "number") {
					// 	attrs.query.write(() => (e.target as HTMLInputElement)?.valueAsNumber);
					// } else {
					// 	attrs.query.write(() => (e.target as HTMLInputElement)?.value);
					// }
				},
				slot: attrs.slot,
				id: attrs.id,
			},
			children
		)
	}
