/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable no-mixed-spaces-and-tabs */
/* globals process */
import m, * as bacta from "bacta"
import "../../uppy.min.css"

const css = bacta.css

const TRANSLOADIT_TEMPLATE = "60a573a8754f4787be1d1df511c6ec5b"
const TRANSLOADIT_AUTH_KEY = "3ed2a5ce24a4e1c14602e5b638f7978f"

function Uppy({
	attrs: {
		uppy: options,
		Transloadit: transloaditOptions,
		UppyDashboard: dashboardOptions,
		data = {
			features: () => ({
				uppyWebcam: false,
				uppyGoogleDrive: true,
				uppyDropbox: true,
				uppy: true,
			}),
		},
		instance = () => {},
		v,
	},
}: any) {
	const Uppy = v.useStream() as bacta.Stream<any>
	const domNodeRendered = v.useStream() as bacta.Stream<any>

	bacta.Stream.merge([Uppy, domNodeRendered]).map(([Uppy, target]) => {
		// eslint-disable-next-line prefer-const
		let uppy = Uppy.Core({ id: target, ...options })
			.use(Uppy.Transloadit, { ...transloaditOptions, target })
			.use(Uppy.Dashboard, { ...dashboardOptions, target })

		instance(uppy)

		if (data.features().uppyWebcam) {
			uppy.use(Uppy.Webcam, {
				target: Uppy.Dashboard,
				facingMode: "environment",
				mirror: false,
			})
		}

		if (data.features().uppyGoogleDrive) {
			uppy.use(Uppy.GoogleDrive, {
				target: Uppy.Dashboard,
				companionUrl: "https://companion.uppy.io",
			})
		}

		if (data.features().uppyDropbox) {
			uppy.use(Uppy.Dropbox, {
				target: Uppy.Dashboard,
				companionUrl: "https://companion.uppy.io",
			})
		}

		return null
	})

	const src = "https://transloadit.edgly.net/releases/uppy/v1.7.0/uppy.min.js"

	return {
		onremove: () => Uppy.end(true),
		view() {
			return data.features().uppy
				? m(
						".uppy",
						css`
							min-height: 248px;
							opacity: 0;
						`,
						// .$animate("ease-in-out 1s 1s forwards", {
						// 	from: "o 0",
						// 	to: "o 1",
						// }),
						m("script", {
							src,
							// eslint-disable-next-line no-undef
							onload: () => Uppy.set((window as any).Uppy),
						}),
						m("", { oncreate: ({ dom }) => domNodeRendered.set(dom) })
				  )
				: m(".uppy")
		},
	}
}

const simple = ({
	data,
	getFields,
	instance,
	width = 300,
	height = 250,
	uppy = {},
	v,
}: any) =>
	m(Uppy, {
		v,
		uppy: Object.assign(
			{
				autoProceed: false,
				allowMultipleUploads: true,
				restrictions: {
					maxNumberOfFiles: 50,
				},
			},
			uppy
		),
		Transloadit: {
			waitForEncoding: true,
			waitForMetadata: true,
			getAssemblyOptions() {
				return data.api.files.getTransLoaditSignature({
					params: {
						auth: {
							key: TRANSLOADIT_AUTH_KEY,
							expires: new Date(
								new Date().getTime() + 60 * 5 * 1000
							).toISOString(),
						},
						template_id: TRANSLOADIT_TEMPLATE,
					},
					fields: getFields(),
				})
			},
		},
		UppyDashboard: {
			autoProceed: false,
			id: "Dashboard",
			target: "body",
			metaFields: [],
			//,plugins: ['Webcam', 'Transloadit', 'FileInput']
			trigger: "#uppy-select-files",
			inline: true,
			proudlyDisplayPoweredByUppy: false,
			width,
			height,
			showProgressDetails: true,
			thumbnailWidth: 40,
		},
		data,
		instance,
	})

export default {
	Uppy,
	simple,
}
