/* eslint-disable no-unsafe-optional-chaining */
import m, * as bacta from "bacta"
import { ConfirmPaymentData, StripeError, loadStripe } from "@stripe/stripe-js"

type option = {
	layout?: "accordion" | "tabs"
	defaultValues: {
		billingDetails: {
			name: string
			email: string
			phone: string
			address?: {
				line1: string
				line2: string
				city: string
				state: string
				country: string
				postal_code: string
			}
		}
		paymentMethods?: { ideal: { bank: string } }
	}
	business?: { name: string }
	paymentMethodOrder?: string[]
	paymentMethodConfiguration?: string
	fields?: {
		billingDetails: {
			name: "never" | "auto"
			email: "never" | "auto"
			phone: "never" | "auto"
			address: "never" | "auto" | object
		}
	}
	readOnly?: boolean
	terms?: {
		applePay: "auto" | "always" | "never"
		auBecsDebit: "auto" | "always" | "never"
		bancontact: "auto" | "always" | "never"
		card: "auto" | "always" | "never"
		cashapp: "auto" | "always" | "never"
		googlePay: "auto" | "always" | "never"
		ideal: "auto" | "always" | "never"
		paypal: "auto" | "always" | "never"
		sepaDebit: "auto" | "always" | "never"
		sofort: "auto" | "always" | "never"
		usBankAccount: "auto" | "always" | "never"
	}
	wallets?: {
		applePay: "auto" | "never"
		googlePay: "auto" | "never"
	}
}

type StripeAttrs = {
	mode?: string
	appearance: {
		theme: "flat" | "stripe" | "night"
		variables?: object
	}
	amount: number
	options: Partial<option>
	currency: string
	error: any
	postop?: any
	address: bacta.Stream<any>
	group_payment_id?: string
	writeToLoad: any
	makeOrder: any
	successfulOrder: any
	state: any
}

// layout - Layout for the Payment Element.
// defaultValues - Initial customer information to display in the Payment Element.
// business - Information about your business to display in the Payment Element.
// paymentMethodOrder - Order to list payment methods in.
// fields - Whether to display certain fields.
// readOnly - Whether payment details can be changed.
// terms - Whether mandates or other legal agreements are displayed in the Payment Element. The default behaviour is to show them only when necessary.
// wallets - Whether to show wallets such as Apple Pay or Google Pay. The default is to show them when possible.

export type MainStripeAttrs = StripeAttrs

export const HarthStripe: bacta.ClosureComponent<MainStripeAttrs> = () => ({
	async oncreate({ attrs }) {
		const stripe = await loadStripe(
			// STRIPE_KEY
			"pk_test_51NDfIME4NQ0S7DazIagOXSsRHcMMAssa1aQqUxTVNO1DqJ6gTxqkb68ggQpgiGaDmWXzdx7wuD2woDa273MeIfkZ00Ncc0Y85c"
		)
		if (!stripe) {
			console.error("Could not load stripe")
			return
		}

		const elements = stripe.elements({
			appearance: attrs.appearance,
			mode: "payment",
			amount: attrs.amount,
			currency: attrs.currency,
			captureMethod: "manual",
		})

		const paymentElement = elements.create("payment", {
			layout: "accordion",
			wallets: {
				applePay: "auto",
				googlePay: "auto",
			},
			paymentMethodConfiguration: "pmc_1NDfP6E4NQ0S7Daz11IGmh8a",
			// STRIPE_CONFIG,
			...attrs.options,
		})

		if (paymentElement) {
			paymentElement.mount("#payment-element")
		}

		const submitBtn = document.getElementById("submit-pay") as HTMLButtonElement

		const handleError = (stripeError: StripeError) => {
			console.error(stripeError)
			if (submitBtn) {
				submitBtn.disabled = false
			}
		}

		submitBtn?.addEventListener("click", async (event) => {
			event.preventDefault()
			if (submitBtn?.disabled) {
				return
			}

			if (submitBtn) {
				submitBtn.disabled = true
			}

			// Trigger form validation and wallet collection
			const { error: submitError } = (await elements?.submit()) ?? {}
			if (submitError) {
				handleError(submitError)
				return
			}

			// Create the PaymentIntent and obtain clientSecret
			const { client_secret } = await m.request<{
				client_secret: string
			}>("/api/stripe/create-intent", {
				method: "POST",
				body: { amount: attrs.amount, currency: attrs.currency },
			})

			// Confirm the PaymentIntent using the details collected by the Payment Element
			const confirmedPayment = await stripe?.confirmPayment({
				elements: elements ?? undefined,
				clientSecret: client_secret,
				redirect: "if_required",
				confirmParams: {
					return_url: window.location.origin,
					shipping: {
						address: {
							line2: attrs.address.get()?.address?.district,
							line1: [
								attrs.address.get()?.address?.houseNumber,
								attrs.address.get()?.address?.street,
							].join(" "),
							city: attrs.address.get()?.address?.city,
							state: attrs.address.get()?.address?.state,
							country:
								attrs.address.get()?.address?.countryName == "Australia"
									? "AU"
									: "",
							postal_code: attrs.address.get()?.address?.postalCode,
						},
						name: attrs?.options?.defaultValues?.billingDetails.name,
						tracking_number: attrs.group_payment_id,
					},
				} as ConfirmPaymentData,
			})

			if (confirmedPayment && confirmedPayment.error) {
				attrs.error.update(() => confirmedPayment.error.message)
			} else {
				attrs.error.update(() => "")

				attrs.writeToLoad(true)

				const order = await attrs.makeOrder()

				await m
					.request<any[]>("/api/stripe/confirm-intent", {
						method: "POST",
						body: {
							paymentIntent: confirmedPayment.paymentIntent,
							order,
						},
					})
					.then(
						(res: any) => {
							return res
						},
						(e: any) => {
							console.log(e.response)
							return e.response.error == "Unauthorized call"
						}
					)

				// Route to the orders screen
				attrs.state.update(() => "Home")

				await attrs.successfulOrder()

				attrs.writeToLoad(false)
			}

			submitBtn.disabled = false
		})
	},
	view: () => {
		return m(
			"#payment-element",
			bacta.css`
				& {
					/* For some reason it introduces horizontal scrollbars*/
					width: 99%;
				}
			`
		)
	},
})
