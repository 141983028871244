import m, * as bacta from "bacta"
import css from "../../main.module.css"

import "@shoelace-style/shoelace/dist/themes/light.css"
import "@shoelace-style/shoelace/dist/components/details/details.js"

export type DetailAttrs = {
	open?: boolean
	summary?: string
	disabled?: boolean
	class?: string
} & bacta.Attributes

export const Details: bacta.ClosureComponent<DetailAttrs> = () => {
	return {
		view: ({ attrs, children }) => {
			return m("sl-details." + css[attrs.class || ""], attrs, children)
		},
	}
}
