/* eslint-disable @typescript-eslint/no-explicit-any */
import { Store, Vnode } from "bacta"
import {
	deliveryType,
	gpsType,
	itemType,
	orderType,
	supplierType,
} from "./types"
export type FormalAddress = {
	access: Array<{ lat: number; lng: number }>
	address: {
		city: string
		countryCode: string
		countryName: string
		district: string
		houseNumber: string
		label: string
		postalCode: string
		state: string
		stateCode: string
		street: string
	}
	distance: number
	houseNumberType: string
	id: string
	language: string
	mapView: {
		east: number
		north: number
		south: number
		west: number
	}
	position: {
		lat: number
		lng: number
	}
	resultType: string
	title: string
}
export const initialState = {
	user: {} as {
		user_id: string
		picture: string
		email: string
		name: string
		cards: Array<object>
		phone: string
		address: string
	},
	loading: true as boolean,
	userload: true as boolean,
	organization_id: 1,
	organization: null as any,
	build_id: null as string | null,
	bid_id: null as string | null,
	profile_id: null as string | null,
	availableTimeFrames: ["Now", "Within 2 hours", "Within 4 hours", "Same Day"],
	timeframe: "" as "Now" | "Within2hours" | "Within4hours" | "SameDay",
	map: {} as any,
	order: "" as any,
	orderid: "" as string,
	datefilter: false as boolean,
	orderdates: null as string | null,
	orderdatee: null as string | null,
	supplierInstructions: null as {} | null,
	orderFilters: false as boolean,
	vehicle: "" as string,
	newAddress: false,
	newName: false,
	newPhone: false,
	newInstructions: false,
	newDescription: false,
	gettinglocation: false,
	charge: {} as any,
	deliveryTo: "" as string,
	deliveryToMarker: null as any,
	order_description: "" as string,
	order_instructions: "" as string,
	order_contact_name: "" as string,
	order_phone: "" as string,
	order_email: "" as string,
	vehilceOptions: [] as Array<string>,
	addressOptions: [] as Array<string>,
	nearAddressOptions: [] as Array<object>,
	phoneOptions: [] as Array<string>,
	nameOptions: [] as Array<string>,
	AutoFillOptions: [] as Array<any>,
	checkoutindex: [] as Array<deliveryType>,
	basket: [] as Array<itemType>,
	recent: [] as Array<itemType>,
	suppliers: [] as Array<supplierType>,
	// supplier: null as supplierType | null,
	state: "",
	pstate: "",
	category: null as string | null,
	item: {} as any,
	newitem: '' as string,
	updateitem: '' as string,
	orders: [] as Array<orderType>,
	itemid: "" as string | null,
	info: {} as any,
	headerIcon: null as string | null,
	locationid: "" as string | null,
	items: [] as Array<itemType>,
	trackers: [] as Array<gpsType>,
	subscription: {
		subscription_start: new Date().getTime(),
		subscription_end: new Date().getTime() + 100000,
		subscription_name: "Enterprise" as "Enterprise" | "Professional" | "Basic",
		subscription_bids_remaining: 3,
		subscription_builds_remaining: 5,
	},
	uppy: null as null | any,
	files: [],
	search: "",
	searchItems: "",
	qty: 0,
	navmenuOpen: false,
	drawOpen: false,
	photoOpen: false,
	storeopen: null,
	ValidatedWebAddress: { lat: 0, lng: 0 } as { lat: number; lng: number },
	ValidatedAddress: "" as string,
	FormalAddress: {} as FormalAddress,
	AddressError: "",

	PaymentError: "",
	paySubmit: "",

	status: "",

	min_supplier_items_weight: 0,
	min_supplier_items_height: 0,
	min_supplier_items_wdith: 0,
	min_supplier_items_length: 0,
	min_supplier_locations_items_cost: 0,

	max_supplier_items_weight: 0,
	max_supplier_items_height: 0,
	max_supplier_items_wdith: 0,
	max_supplier_items_length: 0,
	max_supplier_locations_items_cost: 0,

	supplier_name: "",
	supplier_items_name: "",
	supplier_items_description: "",
	supplier_items_weight_min: 0,
	supplier_items_height_min: 0,
	supplier_items_wdith_min: 0,
	supplier_items_length_min: 0,
	supplier_locations_items_cost_min: 0,
	supplier_items_weight_max: 0,
	supplier_items_height_max: 0,
	supplier_items_wdith_max: 0,
	supplier_items_length_max: 0,
	supplier_locations_items_cost_max: 0,
	supplier_items_category: "",
	supplier_items_industry: [] as Array<string>,
	supplier_items_specifications: "",
	supplier_items_features: "",
	supplier_locations_address: 0,
	supplier_locations_items_available: true,
	industryCategories: [],
	industries: [
		"Demolition",
		"Brick ",
		"Concrete Works",
		"BrickLaying",
		"Excavation in Turf",
		"Excavation in Rock",
		"Timber Framing",
		"Timber Decking",
		"Carpentry",
		"Skylighting",
		"Fascia & Gutter Works",
		"Roofing - Tiles",
		"Roofing - Drainage",
		"Temporary Fencing",
		"Electrical",
		"Air Conditioning",
		"Fire Protection",
		"Plumbing - Trenching",
		"Plumbing - Storm Water Connections",
		"Plumbing - Sewer Connections",
		"Plumbing - Pits",
		"Plumbing - Connection",
		"Plumbing - Water Proofing",
		"Weatherboard Install",
		"Plasterboard Install",
		"Tiling",
		"Window Installs",
		"Door Installs",
		"Joinery",
		"Rendering",
		"Painting",
		"Termite Protection",
		"Cleaning",
		"Waste Disposal",
		"Steel Works",
		"Balustrades",
		"Scaffolding",
		"Gyprocking",
		"Waterproofing",
		"Caulking",
		"Glazing",
		"Splashback",
		"Blinds",
		"Landscaping",
		"Geological Survey",
		"Feasibility Survey",
		"Planners Survey",
		"Architectual Consultancy Services",
		"Environmental Consultancy Services",
		"Structural Consultancy Services",
		"Hydraulic Consultancy Services",
		"Marketing Services",
		"Automotive",
		"Building",
	],

	itemCategories: [] as Array<{
		supplier_items_category: string
		supplier_items_icon: string
		count: number
	}>,

	categories: [
		{
			supplier_items_category: "Fasteners & Adhesives",
			supplier_items_icon: "assets/screws.png"
		},
		{
			supplier_items_category: "Power Tools",
			supplier_items_icon: "assets/drill.png"
		},
		{
			supplier_items_category: "Hand Tools",
			supplier_items_icon: "assets/hammer.png"
		},
		{
			supplier_items_category: "Safety & Protection",
			supplier_items_icon: "assets/hatgolves.png",
		},
		{
			supplier_items_category: "Testing and Measuring",
			supplier_items_icon: "assets/tape-measure.png"
		},		
		{
			supplier_items_category: "Cleaning",
			supplier_items_icon: "assets/Vaccum _ steam cleaners-gold.png",
		},
	]	
}

export type State = typeof initialState

// Do not export `store` directly, use useStore() in child components
//
// Why:
//
// In bacta, we'll probably add a feature from React that mithril
// doesn't have called "Context"
//
// It lets a deeply nested child access some config/props/state
// from an ancestor component without manually threading all
// the props all the way down.
//
// We're sort of mocking that out right now until bacta has it
// but the fact it injects the dependency is useful when writing tests.
// If you directly import store here, it will make it harder to migrate later.
let store: Store<State>

export function useStore(v?: Vnode) {
	if (!store && v) {
		const state = v.useStream(initialState)
		store = v.useStore("@", state)
	}

	const s = store.prop("state")
	const ps = store.prop("pstate")
	const b = store.prop("basket")
	const d = store.prop("deliveryTo")
	const o = store.prop("orderid")
	const t = store.prop("trackers")
	const l = store.prop("loading")
	const u = store.prop("userload")
	const map = store.prop("map")

	const categorySearch = store.prop("search")
	const category = store.prop("category")
	const itemSearch = store.prop("searchItems")
	const addressError = store.prop("AddressError")
	const ValidatedWebAddress = store.prop("ValidatedWebAddress")
	const ValidatedAddress = store.prop("ValidatedAddress")
	const nearAddressOptions = store.prop("nearAddressOptions")
	const FormalAddress = store.prop("FormalAddress")

	const name = store.prop("supplier_name")
	const items_name = store.prop("supplier_items_name")
	const items_description = store.prop("supplier_items_description")
	const items_weight_min = store.prop("supplier_items_weight_min")
	const items_height_min = store.prop("supplier_items_height_min")
	const items_wdith_min = store.prop("supplier_items_wdith_min")
	const items_length_min = store.prop("supplier_items_length_min")
	const locations_items_cost_min = store.prop(
		"supplier_locations_items_cost_min"
	)
	const items_weight_max = store.prop("supplier_items_weight_max")
	const items_height_max = store.prop("supplier_items_height_max")
	const items_wdith_max = store.prop("supplier_items_wdith_max")
	const items_length_max = store.prop("supplier_items_length_max")
	const locations_items_cost_max = store.prop(
		"supplier_locations_items_cost_max"
	)
	const items_category = store.prop("supplier_items_category")
	const items_industry = store.prop("supplier_items_industry")
	const items_specifications = store.prop("supplier_items_specifications")
	const items_features = store.prop("supplier_items_features")
	const locations_address = store.prop("supplier_locations_address")
	const locations_items_available = store.prop(
		"supplier_locations_items_available"
	)
	const organization = store.prop("organization")
	const items = store.prop("items")

	return {
		store,
		s,
		b,
		d,
		o,
		t,
		l,
		u,
		ps,
		map,
		categorySearch,
		category,
		itemSearch,
		addressError,
		ValidatedWebAddress,
		name,
		items_name,
		items_description,
		items_weight_min,
		items_height_min,
		items_wdith_min,
		items_length_min,
		locations_items_cost_min,
		items_weight_max,
		items_height_max,
		items_wdith_max,
		items_length_max,
		locations_items_cost_max,
		items_category,
		items_industry,
		items_specifications,
		items_features,
		locations_address,
		locations_items_available,
		ValidatedAddress,
		nearAddressOptions,
		FormalAddress,
		organization,
		items,
	}
}
