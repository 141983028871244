import { Select, SelectAttrs } from "../sl/select"
import { HarthOption } from "./option"
import m, { BactaComponent, Store } from "bacta"

type Attrs = {
	query: Store<string[]> | Store<string>
	options: Array<string>
}

type BaseAttrs = {
	sl?: Partial<SelectAttrs>
	postop?: Function
}

export type MainAttrs = BaseAttrs & Attrs

export const HarthSelect: BactaComponent<MainAttrs> = () => {
	return ({ attrs: { sl = {}, options = [], ...attrs } }) => {
		return m(
			Select,
			{
				["onsl-change"]: (e) => {
					const v = (e.target as HTMLInputElement)?.value
					attrs.query.update(() =>
						Array.isArray(v) ? v : (v.replaceAll("_", " ") as any)
					)
					if (attrs.postop) {
						attrs.postop(v)
					}					
				},
				...sl,
				value: Array.isArray(attrs.query.get())
					? attrs.query.get()
					: (attrs.query.get() + "")
							// .trim()
							// .replaceAll(",", "")
							.replaceAll(" ", "_"),
			},
			options.map((o) =>
				m(HarthOption, { sl: { value: o.trim().replaceAll(" ", "_") } })
			)
		)
	}
}
