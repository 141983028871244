/* eslint-disable no-mixed-spaces-and-tabs */
// todo-james paragraph isn't a regex, either rename module/function etc

/**
 * Formats a vlaue into money formats for human representation.
 *
 * e.g. prettyKey('500520') //=> $ 500,520.00
 */
// import { Store } from "jaforbes-s/store.js";
import m, { Store } from "bacta"
import { stringify } from "querystring"

async function getBounds(store: Store<any>) {
	const [bounds] = await sql`
		select
			min(supplier_items_weight) as minweight
			,min(supplier_items_height) as minheight
			,min(supplier_items_wdith) as minwdith
			,min(supplier_items_length) as minlength
			,min(supplier_locations_items_cost) as mincost
			
			,max(supplier_items_weight) as maxweight
			,max(supplier_items_height) as maxheight
			,max(supplier_items_wdith) as maxwdith
			,max(supplier_items_length) as maxlength
			,max(supplier_locations_items_cost) as maxcost

		from app.supplier_locations_items
		left join app.supplier_items using(supplier_items_id)
	`

	store.prop("min_supplier_items_weight").update(() => bounds.minweight)
	store.prop("min_supplier_items_height").update(() => bounds.minheight)
	store.prop("min_supplier_items_wdith").update(() => bounds.minwdith)
	store.prop("min_supplier_items_length").update(() => bounds.minlength)
	store.prop("min_supplier_locations_items_cost").update(() => bounds.mincost)

	store.prop("max_supplier_items_weight").update(() => bounds.maxweight)
	store.prop("max_supplier_items_height").update(() => bounds.maxheight)
	store.prop("max_supplier_items_wdith").update(() => bounds.maxwdith)
	store.prop("max_supplier_items_length").update(() => bounds.maxlength)
	store.prop("max_supplier_locations_items_cost").update(() => bounds.maxcost)
}

async function setIndustryOptions(store: Store<any>) {
	const industries = await sql`
		select distinct 
			supplier_items_industry
		from app.supplier_items
	`

	store
		.prop("industryCategories")
		.update(() =>
			industries
				.map((i: any) => i && i.supplier_items_industry)
				.filter((a) => a)
		)

	store
		.prop("industries")
		.update(() => [
			...new Set(
				[].concat(
					store.prop("industries").get(),
					store.prop("industryCategories").get()
				)
			),
		])
}

async function searchItems(store: Store<any>, s = "") {
	const basket = store.prop("basket").get()
	const category = store.prop("category").get() || ""
	const search = s

	const supplier = store.prop("supplier_name").get()
	const name = store.prop("supplier_items_name").get()
	const description = store.prop("supplier_items_description").get()

	const minweight = store.prop("supplier_items_weight_min").get()
	const minheight = store.prop("supplier_items_height_min").get()
	const minwdith = store.prop("supplier_items_wdith_min").get()
	const minlength = store.prop("supplier_items_length_min").get()
	const mincost = store.prop("supplier_locations_items_cost_min").get()

	const maxweight = store.prop("supplier_items_weight_max").get()
	const maxheight = store.prop("supplier_items_height_max").get()
	const maxwdith = store.prop("supplier_items_wdith_max").get()
	const maxlength = store.prop("supplier_items_length_max").get()
	const maxcost = store.prop("supplier_locations_items_cost_max").get()

	const industry = store.prop("supplier_items_industry").get()
	const specifications = store.prop("supplier_items_specifications").get()
	const features = store.prop("supplier_items_features").get()
	const within = store.prop("supplier_locations_address").get()
	const currentlocation = store.prop("ValidatedWebAddress").get()
	const available = store.prop("supplier_locations_items_available").get()
	const currentlocationlat = currentlocation.lat || 0
	const currentlocationlng = currentlocation.lng || 0
	const supplierid =
		store.prop("organization").get() &&
		store.prop("organization").get().supplier_id
	const currentday = new Date().getDay()
	const currenttime = [
		new Date().getHours(),
		":",
		new Date().getMinutes(),
	].join("")
	const [itemCategories] = await sql`
		with checkout as (
			select * from json_to_recordset(${basket}::json )
			as x(
				"supplier_items_id" uuid,
				"supplier_locations_id" uuid,
				"quantity" real
			)												
		)
		,openclose as (
			select
				supplier_locations_id 
				,(
					case when ${currentday} = 0
					then SL."supplier_locations_sunday_open"
					when ${currentday} = 1
					then SL."supplier_locations_monday_open"
					when ${currentday} = 2
					then SL."supplier_locations_tuesday_open"
					when ${currentday} = 3
					then SL."supplier_locations_wednesday_open"
					when ${currentday} = 4
					then SL."supplier_locations_thursday_open"
					when ${currentday} = 5
					then SL."supplier_locations_friday_open"
					else SL."supplier_locations_saturday_open"
					end
				) as opentime
				,(
					case when ${currentday} = 0
					then SL."supplier_locations_sunday_close"
					when ${currentday} = 1
					then SL."supplier_locations_monday_close"
					when ${currentday} = 2
					then SL."supplier_locations_tuesday_close"
					when ${currentday} = 3
					then SL."supplier_locations_wednesday_close"
					when ${currentday} = 4
					then SL."supplier_locations_thursday_close"
					when ${currentday} = 5
					then SL."supplier_locations_friday_close"
					else SL."supplier_locations_saturday_close"
					end
				) as closetime	
			from app.supplier_locations SL						
		)
		,items as (
			select 
				* 
				,C.supplier_items_id as checked
				,SLI.supplier_items_id
				,SLI.supplier_items_id as siid
				,SLI.supplier_locations_id
				,SLI.deleted_at
				,(case when C.quantity is null or C.quantity = 0 then 0 else C.quantity end) as quantity
				,0 as qtyinput
				,calculate_distance(
					SL.supplier_locations_gps[0]
					, SL.supplier_locations_gps[1]
					, ${currentlocationlat}
					, ${currentlocationlng}
					, 'K'
				)
				,(
					supplier_items_name
					|| supplier_items_description
					|| supplier_items_industry
					|| supplier_items_serial
					|| supplier_items_specifications
					|| (supplier_items_features::text)
				) as search
				,OC.opentime
				,OC.closetime
				,SL.supplier_locations_open
				,(
					case when
						( ${currenttime}::time + interval '1 hour' < OC.closetime::time )
						or  ( ${currenttime}::time < OC.opentime::time )
						or SL.supplier_locations_open = false
					then 'Closed'
					else 'Open'
					end
				) as storestatus	 
			from app.supplier_locations_items SLI
			inner join app.supplier_items SI on  SI.supplier_items_id = SLI.supplier_items_id	
			inner join app.supplier_locations SL on SL.supplier_locations_id = SLI.supplier_locations_id	
			inner join app.suppliers S on SL.supplier_id = S.supplier_id
			inner join openclose OC on OC.supplier_locations_id = SLI.supplier_locations_id
			left join checkout C on 
				SL.supplier_locations_id = C.supplier_locations_id	
				and SI.supplier_items_id = C.supplier_items_id
			where 
				SLI.deleted_at is null
				and (S.supplier_id = ${supplierid} or ${supplierid}::uuid is null)
				and (S.supplier_id = ${supplierid} or supplier_items_image is not null)
			
		)
		,sitems as (
			select 
				*
			from items as r

			where checked is not null
			or (( ${search} = '' or lower(r.search::text) LIKE lower(('%' || ${search} || '%')))
			and ( (${minweight} = 0 and ${maxweight} = 0) or ( supplier_items_weight > ${minweight} and supplier_items_weight < ${maxweight} ) ) 
			and ( (${minheight} = 0 and ${maxheight} = 0) or ( supplier_items_height > ${minheight} and supplier_items_height < ${maxheight} ) ) 
			and ( (${minwdith} = 0 and ${maxwdith} = 0) or ( supplier_items_wdith > ${minwdith} and supplier_items_wdith < ${maxwdith} ) ) 
			and ( (${minlength} = 0 and ${maxlength} = 0) or ( supplier_items_length > ${minlength} and supplier_items_length < ${maxlength} ) ) 
			and ( (${mincost} = 0 and ${maxcost} = 0) or ( supplier_locations_items_cost > ${mincost} and supplier_locations_items_cost < ${maxcost}) ) 
			
			and ( ${industry} = '' or supplier_items_industry = ANY( ${industry} ) ) 

			and ( ${category} = '' or lower(supplier_items_category) LIKE lower(('%' || ${category} || '%')))

			and ( ${features} = '' or supplier_items_features @> ${features})
			
			and ( ${supplier} = '' or lower(supplier_name) LIKE lower(('%' || ${supplier} || '%')))
			and ( ${name} = ''  or lower(supplier_items_name) LIKE lower(('%' || ${name} || '%'))) 
			and ( ${description} = ''  or lower(supplier_items_description) LIKE lower(('%' || ${description} || '%')))
			and ( ${specifications} = ''  or lower(supplier_items_specifications) LIKE lower(('%' || ${specifications} || '%')))
			
			and ( ${available} = supplier_locations_items_available )
			and ( calculate_distance < ${within} or ${within} = 0)
			and ( 
				calculate_distance < 110 
				or (${currentlocation.lat}::float = 0 and ${currentlocation.lng}::float = 0)
			))
		)
		,scategories as (
			select 
				supplier_items_category
				,supplier_items_icon
				,count(distinct r.siid)
				,calculate_distance
			from sitems as r
			where ( ${search} = '' or lower(r::text) LIKE lower(('%' || ${search} || '%')))
			and supplier_items_category is not null and supplier_items_icon is not null 
			group by supplier_items_category, supplier_items_icon, calculate_distance
		)
		select 
			( select json_agg( SI.* ) from sitems SI ) as items
			,( select json_agg( CA.* ) from scategories CA ) as categories

	`

	store.prop("items").update(() => itemCategories.items || [])
	store.prop("itemCategories").update(() => itemCategories.categories || [])
	store.prop("loading").update(() => false)
}

async function setMetaData(store: Store<any>, user: any) {
	if (user) {
		const AutoFillOptions: any[] = await sql`
			select 
				"order_contact_name"
				,"order_phone"
				,"order_address"
				,"order_description"
				,"order_notes"
				,"supplier_items_category"
				,"supplier_items_industry"
				,"supplier_items_icon"
				,"supplier_items_id"
			from app.orders
			left join app.supplier_items using(supplier_items_id)
			limit 50
		`
		// NOTE WHEN YOU SEND SHERPA "AUSTRALIA" IN THE ADDRESS, THE ADDRESS VALIDATION IS AMBIGIOUS

		store
			.prop("addressOptions")
			.update(() => [...new Set(AutoFillOptions.map((a) => a.order_address))])
		store
			.prop("phoneOptions")
			.update(() => [...new Set(AutoFillOptions.map((a) => a.order_phone))])
		store
			.prop("nameOptions")
			.update(() => [
				...new Set(AutoFillOptions.map((a) => a.order_contact_name)),
			])

		const suppliers: any[] = await sql`
			select 
				"supplier_id"
				,"supplier_name"
				,"organization_details_name"
				,"organization_details_logo_url"
				,"organization_details_id"
				,coalesce(json_agg(SL.*) filter (where SL.supplier_locations_id is not NULL), '[]') as supplier_locations
			from app.suppliers
			inner join app.organizations using(organization_id)
			left join app.organization_details using(organization_id)
			left join app.supplier_locations SL using(supplier_id)
			group by 
				"supplier_id"
				,"supplier_name"
				,"organization_details_name"
				,"organization_details_logo_url"
				,"organization_details_id"		
		`

		store.prop("suppliers").update(() => suppliers)
		const recentIndex = {} as any
		store.prop("recent").update(() =>
			AutoFillOptions.filter((a) => {
				const k = [
					a.supplier_items_category,
					a.supplier_items_industry,
					a.supplier_items_icon,
				].join()
				if (!recentIndex[k]) {
					recentIndex[k] = true
				}
			})
		)

		const [organization] = (await sql`
				select
					*
				from app.organizations
				where user_id = ${user.user_id}`) as Array<any>
		return organization
	} else {
		// store.prop("state").update(() => "")
		return { organization_id: null }
	}
}

async function initialize(store: Store<any>) {
	try {
		const [user] = (await sql`

				select 
					user_id
					, email
					, name
					, picture
					, organization_id					
					, to_json(array_remove(array_agg( distinct UA.* ), null)) as users_addresses					
				from app.users U
				left join app.users_addresses UA using(user_id)
				group by U.user_id, U.email, U.name, U.picture, U.organization_id	
		`) as Array<any>

		store.prop("user").update(() => user)

		await setMetaData(store, user)

		await setIndustryOptions(store)
		await getBounds(store)
		await searchItems(store)

		const [orgsupplier] = (await sql`
				select 
					* 
				from app.organizations
				left join app.suppliers using(organization_id)
				where user_id = ${user.user_id}`) as Array<any>

		store.prop("organization").update(() => orgsupplier)

		await getLocation(store, store.prop("ValidatedWebAddress"))

		if (orgsupplier && orgsupplier.supplier_id) {
			store.prop("state").update(() => "Home")
			// store.prop("state").update(() => "Search")
		} else {
			store.prop("state").update(() => "Search")
		}

		store.prop("userload").update(() => false)
	} catch (e) {
		console.log(e)
		store.prop("state").update(() => "")
		store.prop("userload").update(() => false)
		return e
	}
}

async function getLocation(store: Store<any>, address: Store<any>) {
	const processing = store.prop("gettinglocation")
	const setlatlong = (s: any) => {
		address.update(() =>
			s
				? {
						lat: Number(s.coords.latitude),
						lng: Number(s.coords.longitude),
						// lat: -33.93270861804031,
						// lng: 151.1179572846583,
				  }
				: {}
		)
		processing.update(() => false)
		return false
	}

	// eslint-disable-next-line no-undef
	if (!navigator.geolocation) {
	} else {
		// eslint-disable-next-line no-undef
		return navigator.geolocation.getCurrentPosition(setlatlong, setlatlong)
	}
}

function sql(query: TemplateStringsArray, ...args: any[]): Promise<any[]> {
	const token = ""
	return m.request<any[]>("/api/sql/select", {
		method: "POST",
		body: { query, args, tag: "sql" },
		headers: {
			Authorization: "Bearer " + token,
		},
	})
}

function here(
	address = "" as string,
	{ lat, lng }: { lat: number; lng: number }
): Promise<any[]> {
	return m
		.request("/api/here/address", {
			method: "POST",
			body: {
				address,
				types: "houseNumber",
				lat,
				lng,
			},
		})
		.then(
			(res: any) => {
				// console.log("HERE", res)
				return res
			},
			(e: any) => {
				console.log(e.response)
				return e.response.error == "Unauthorized call"
			}
		)
}

function sherpa({
	endpoint,
	delivery_address,
	items,
	user_id,
	deliveryDetails,
	group_payment_id,
	paymentid,
	bookingid,
}: {
	endpoint: string
	delivery_address?: string
	items?: Array<any>
	user_id?: string
	deliveryDetails?: object
	group_payment_id?: string
	paymentid?: string | null
	bookingid?: string
}) {
	const data = {
		delivery_address,
		items,
		user_id,
		group_payment_id,
		paymentid,
		deliveryDetails,
		tag: "sherpa",
		bookingid,
	}

	const token = ""
	return m
		.request("/api/sherpa/" + endpoint, {
			method: "POST",
			body: { data },
			headers: {
				Authorization: "Bearer " + token,
			},
		})
		.then(
			(res) => {
				return res
			},
			(e) => {
				console.log(e.response)
				return e.response.error == "Unauthorized call"
			}
		) as any
}

function stripeCharge({
	delivery_id,
	cancel,
	total,
	id,
}: {
	delivery_id: string
	cancel: boolean
	total?: number | string
	id: string
}) {
	const data = {
		delivery_id,
		cancel,
		total,
		id,
	}
	const token = ""
	return m
		.request("/api/stripe/charge", {
			method: "POST",
			body: { data },
			headers: {
				Authorization: "Bearer " + token,
			},
		})
		.then(
			(res) => {
				return res
			},
			(e) => {
				console.log(e.response)
				return e.response.error == "Unauthorized call"
			}
		) as any
}

export {
	sql,
	initialize,
	sherpa,
	searchItems,
	here,
	getLocation,
	setMetaData,
	stripeCharge,
}
