import m, * as bacta from "bacta"
import { Details, DetailAttrs } from "../sl/details"

export type Attrs = {
	query?: bacta.Store<string> | bacta.Store<boolean>
	children?: bacta.Children
}

export type BaseAttrs = {
	sl?: Partial<DetailAttrs>
}

export type MainAttrs = BaseAttrs & Attrs

export const HarthDetails: bacta.ClosureComponent<MainAttrs> = () => ({
	view: ({ attrs: { sl = {}, ...attrs }, children = [] }) => {
		return m(
			Details,
			{
				...sl,
				...attrs,
				// open: attrs.query.get() ? true : false,
				// onclick: async () => {
				// 	attrs?.query?.update(() => !attrs?.query?.get())
				// },
			},
			children
		)
	},
})
